import * as Api from '@ViewModels';
import { StyleDeclarationValue } from 'aphrodite';
import { createHashHistory } from 'history';
import { action, computed, observable } from 'mobx';
import * as React from 'react';
import { v4 as uuidgen } from 'uuid';
import { AccountOperationsViewModel, ImpersonationContextViewModel } from '../viewmodels/AdminViewModels';
import { ISelectBoxOption } from '../web/components/SelectBox';
import { titles } from '../web/styles/colors';
import { useContextGuard } from './hooks/useContextGuard';

export const ApplinksBaseUrl = 'https://links.levitate.ai/applinks';

export const SignatureTemplateEmailParserDomain = 'signature.levitate.ai';

export const EmojiDefaultSkinToneKey = 'emojiDefaultSkinTone';

/**
 * Right now we have TViewModel and TModel. We can get rid of TModel once everything depeneds on view models. TODO:
 * Ideally, we should probably have a navigaton vm exposed on app state for this kind of thing (mobx-react-router?).
 */
export interface ILocationState<TViewModel, TModel> {
	model?: TModel;
	referrer?: string;
	viewModel?: TViewModel;
}

export interface IBuildInfo {
	date: Date;
	hash: string;
	modifiedFilesCount?: number;
	packageVersion: string;
	tinyMceVersion?: string;
	untrackedFilesCount?: number;
}

export const PersistentStorageConnectEmailStateKey = 'connectEmailState';
export const PersistentStoragePeoplePageStateKey = 'peoplePageState';
export const PersistentStoragePeopleSearchAddedTagsStateKey = 'peopleTagsAddedState';
export const PersistentStorageCompaniesPageStateKey = 'companiesPageState';
export const PersistentStorageUserFilterStateKey = 'campaignReportingUserFilterState';
export const PersistentStorageCampaignStatusFilterStateKey = 'campaignReportingStatusFilterState';
export const PersistentStorageCampaignTypeFilterStateKey = 'campaignReportTypeFilterState';
export const PersistentStorageEmailCategoryFilterStateKey = 'emailCategoryFilterState';
export const PersistentStorageSuppressEmptySatisfactionCardKey = 'suppressEmptySatisfactionCard';
export const PersistentStorageBoardShowArchivedKey = 'boardShowArchived';

export type IBoardShowArchivedStorage = boolean;

export interface IConnectEmailState {
	accountId: string;
	contactImportVisibility?: Api.ResourceVisibility | 'none';
	isReconnect: boolean;
	jobId?: string;
	userId: string;
}

export const DefaultContactsFilterRequest: Api.IContactsFilterRequest = {
	criteria: [
		{
			property: Api.ContactFilterCriteriaProperty.Name,
			value: '',
		},
	],
};

export const DefaultCompaniesSearchRequest: Api.ICompaniesSearchRequest = {
	searches: [
		{
			property: Api.CompanySearchRequestProperty.Name,
			value: '',
		},
	],
};

export const DefaultCompaniesSortDescriptor: Api.ISortDecriptor = {
	sort: 'asc',
	sortBy: 'companyName',
};

export enum NameSortKey {
	FirstName = 'firstName',
	Handle = 'handle',
	HandleReverse = 'handleReverse',
	LastName = 'lastName',
}

export enum ContactSortKey {
	Handle = 'handle',
	HandleReverse = 'handle_reverse',
	KeyDate = 'keyFactsCollection.keyDate.sortKey',
}

export const DefaultContactsSortDescriptor: Api.ISortDecriptor = {
	sort: 'asc',
	sortBy: NameSortKey.Handle,
};

export const PhoneNumberLabels = ['Work', 'Home', 'Mobile', 'Cell', 'Fax', 'Direct', 'Office', 'Other'].map<
	ISelectBoxOption<string>
>(x => ({ title: x, value: x.toLowerCase() }));

export const PhoneNumberLabelDictionary = PhoneNumberLabels.reduce<Api.IDictionary<ISelectBoxOption<string>>>(
	(result, x) => {
		// @ts-ignore
		result[x.value] = x;
		return result;
	},
	{}
);

export const KeepInTouchCommitmentOptions: [Api.KeepInTouchCommitmentInterval, string][] = [
	[Api.KeepInTouchCommitmentInterval.Daily, 'Every Day'],
	[Api.KeepInTouchCommitmentInterval.MultipleTimesAWeek, 'Multiple Times a Week'],
	[Api.KeepInTouchCommitmentInterval.Weekly, 'Once a Week'],
	[Api.KeepInTouchCommitmentInterval.Never, "I don't need calendar reminders"],
];

export type SizeConstraint = 'normal' | 'compact';

export interface IPeoplePageState {
	contactsFilterRequest?: Api.IContactsFilterRequest;
	sortDescriptor?: Api.ISortDecriptor;
}

export const DefaultPeoplePageState: IPeoplePageState = {
	contactsFilterRequest: { ...DefaultContactsFilterRequest },
	sortDescriptor: DefaultContactsSortDescriptor,
};

export interface ICompaniesPageState {
	searchRequest?: Api.ICompaniesSearchRequest;
	sortDescriptor?: Api.ISortDecriptor;
}

export const DefaultCompaniesPageState: ICompaniesPageState = {
	searchRequest: { ...DefaultCompaniesSearchRequest },
	sortDescriptor: { ...DefaultCompaniesSortDescriptor },
};

export interface IPeopleSearchAddedTagsState {
	tags?: string[];
}

export const DefaultPeopleSearchAddedTagsState: IPeopleSearchAddedTagsState = {
	tags: [],
};

export interface IComposerContent {
	conclusion?: string;
	greeting?: string;
	keyFacts?: string;
	personalBusinessUpdate?: Api.IRichContentEditorState;
	subject?: string;
}

export enum SingleEmailGuidedComposerStep {
	Subject,
	KeyFacts,
	PersonalBusinessUpdate,
	Conclusion,
}

export const DefaultEditableNoteContentCSS = `
	body {
		background: transparent;
		color: #767676;
		font-family:"Sora",sans-serif;
		font-size: 14px;
		margin: 0;
		padding: 10px 20px;
	}
`;

export const DefaultEditableActionItemContentCSS = `
	body {
		background: transparent;
		color: #767676;
		font-family:"Sora",sans-serif;
		font-size: 14px;
		margin: 0;
		padding: 0;
	}
`;

export const DefaultRichContentRawCSS = `
	body {
		background: transparent;
		color: ${titles};
		font-family:"Sora",sans-serif;
		font-size: 14px;
		margin: 0;
		padding: 10px 20px;
	}
`;

const InternalLevitateAccountIdArray = [
	'b63aefc7-585a-4730-a33c-d680f4f87efc', // This is the Real Magic account ID
	'b3acb8ce-9fec-4d41-a6f0-02b448cf2fad', // Real Magic - Dev account ID
	'cb70493f-0648-43f8-8a9f-97d437a226fe', // Levitate Internal Marketing Test
];

export const InternalAccountIds = new Set([
	'2b9945ad-f25d-4376-bb75-46ce5dcc58b6', // Levitate Demo account
	'225afcd0-a708-4d3d-957d-9370f7c0bba6', // Levitate Test account
	'1702547b-cb86-41e4-8229-853b4e96a173', // Levitate Coffee account
	'25c7088f-91d2-4ebb-b34b-bac9f9a25503', // Coffee sandbox on Test
	...InternalLevitateAccountIdArray,
]);

/**
 * Just our full on Levitate accounts, excludes special accounts that are still internal, but not our main accounts for a given environment
 */
export const InternalLevitateAccountIds = new Set(InternalLevitateAccountIdArray);

export const MainInternalLevitateAccountId = `b63aefc7-585a-4730-a33c-d680f4f87efc`;

export interface IFullscreenModalHeaderContext {
	headerLeftAccessoryPortalDestinationId?: string;
	headerRightAccessoryPortalDestinationId?: string;
	headerTitlePortalDestinationId?: string;
	onBack?(e?: React.MouseEvent<HTMLElement>): void;
	onClose?(e?: React.MouseEvent<HTMLElement>): void;
}

export const FullscreenModalHeaderContext = React.createContext<IFullscreenModalHeaderContext>({});

export const AppHashHistory = createHashHistory();

export const ModalChildComponentContextKey = 'parentModal';
export const DefaultModalName = 'modal';

export interface IFullscreenModal {
	onRequestClose?(): void;
}

export interface IModal<TResult = any> {
	name?: string;
	onRequestClose(result?: TResult, canceled?: boolean): void;
}

export interface IModalContext<TResult = any> {
	parentModal?: IModal<TResult>;
}

// @ts-ignore
export const ModalContext = React.createContext<IModalContext>(null);
export function useModalContext<TResult = any>() {
	return useContextGuard<IModalContext<TResult>>(ModalContext, 'ModalContext');
}

export interface IPushNotificationMessage {
	accountId?: string;
	clickUrl?: string;
	iconUrl?: string;
	id?: string;
	message?: string;
	/** JSON string of the form [{name: string, id: string},...] */
	referencedResources?: string;
	title?: string;
	type?: string;
	userId?: string;
}

export class AdvancedTagFilter {
	protected mId: string;
	// @ts-ignore
	@observable public tag: string;
	// @ts-ignore
	@observable public type: Api.FilterOperator;

	constructor() {
		this.mId = uuidgen();
	}

	public get id() {
		return this.mId;
	}

	public isEqual = <T extends AdvancedTagFilter = AdvancedTagFilter>(other: T) => {
		if (other) {
			return this === (other as any) || (this.tag === other.tag && this.type === other.type);
		}
		return false;
	};

	public clone = () => {
		const filter = new AdvancedTagFilter();
		filter.mId = this.id;
		filter.tag = this.tag;
		filter.type = this.type;
		return filter;
	};

	@computed
	public get isValid() {
		return !!this.type && !!this.tag;
	}

	public toContactFilterCriteria = () => {
		const filterCriteria: Api.IContactFilterCriteria = {
			op: this.type as unknown as Api.ContactFilterOperator,
			property: Api.ContactFilterCriteriaProperty.Tag,
			value: this.tag,
		};
		return filterCriteria;
	};
}

export class AdvancedTagFilterCollection {
	@observable.ref private mFilters: AdvancedTagFilter[];
	public static instanceWithCriteria(criteria: Api.IContactFilterCriteria[]) {
		const result = new AdvancedTagFilterCollection();
		result.setFiltersWithCriteria(criteria || []);
		return result;
	}

	public static instanceWithFilters(filters: AdvancedTagFilter[]) {
		const result = new AdvancedTagFilterCollection();
		result.mFilters = filters || [];
		return result;
	}

	private static sort(a: AdvancedTagFilter, b: AdvancedTagFilter) {
		if (!a.type && !a.tag) {
			return 1;
		}
		if (!b.type && !b.tag) {
			return -1;
		}
		if (a.type === Api.FilterOperator.And || !a.type) {
			return -1;
		}
		if (b.type === Api.FilterOperator.And || !b.type) {
			return 1;
		}
		if (a.type === Api.FilterOperator.Or) {
			return -1;
		}
		return 1;
	}

	constructor() {
		this.mFilters = [];
	}

	@computed
	public get length() {
		return this.mFilters.length;
	}

	@computed
	public get filters() {
		return this.mFilters;
	}

	@computed
	public get orFilters() {
		return this.mFilters.filter(x => x.type === Api.FilterOperator.Or);
	}

	@computed
	public get andFilters() {
		return this.mFilters.filter(x => x.type === Api.FilterOperator.And);
	}

	@computed
	public get notFilters() {
		return this.mFilters.filter(x => x.type === Api.FilterOperator.Not);
	}

	@action
	public remove = (filter: AdvancedTagFilter) => {
		if (this.mFilters.indexOf(filter) < 0) {
			return;
		}
		const filters = [...this.mFilters];
		filters.splice(filters.indexOf(filter), 1);
		if (filters.length === 1) {
			const first = filters[0];
			if (first.type !== Api.FilterOperator.Not) {
				first.type = Api.FilterOperator.And;
			}
		}
		this.mFilters = filters;
	};

	@action
	public add = (filter: AdvancedTagFilter) => {
		if (this.mFilters.indexOf(filter) >= 0) {
			return;
		}
		const filters = [...this.mFilters];
		filters.push(filter);
		const hasOr = filters.some(x => x.type === Api.FilterOperator.Or);
		filters.forEach(x => {
			if (x.type !== Api.FilterOperator.Not && !!x.type) {
				x.type = hasOr ? Api.FilterOperator.Or : Api.FilterOperator.And;
			}
		});
		this.mFilters = filters;
	};

	@action
	public sort = () => {
		this.mFilters = this.mFilters.sort(AdvancedTagFilterCollection.sort);
	};

	public find = (value: string, options?: { caseInsensitiveSearch?: boolean }) => {
		if (!value) {
			return false;
		}
		const v = !!options && !!options.caseInsensitiveSearch ? value.toLocaleLowerCase() : value;
		return this.mFilters.find(
			x => v === (!!options && !!options.caseInsensitiveSearch ? (x.tag || '').toLocaleLowerCase() : x.tag || '')
		);
	};

	public hasValue = (value: string, options?: { caseInsensitiveSearch?: boolean }) => {
		return !!this.find(value, options);
	};

	public isEqualTo = (other?: AdvancedTagFilterCollection) => {
		if (!other || other.length !== this.length) {
			return false;
		}

		return this.mFilters.every((x, i) => !!x.isEqual(other.mFilters[i]));
	};

	public toCriteriaCollection = () => {
		const criteriaCollection: Api.IContactFilterCriteria[] = [];
		if (this.mFilters.length === 0) {
			return criteriaCollection;
		}

		if (this.mFilters.length === 1) {
			const criteria = this.mFilters[0].toContactFilterCriteria();
			criteriaCollection.push(criteria);
			return criteriaCollection;
		}

		const not: Api.IContactFilterCriteria[] = [];
		const and: Api.IContactFilterCriteria[] = [];
		const or: Api.IContactFilterCriteria[] = [];

		this.mFilters.forEach(filter => {
			const criteria = filter.toContactFilterCriteria();
			if (filter.type === Api.FilterOperator.Not) {
				not.push(criteria);
			} else if (filter.type === Api.FilterOperator.And) {
				and.push(criteria);
			} else if (filter.type === Api.FilterOperator.Or) {
				or.push(criteria);
			}
		});

		if (and.length > 0) {
			criteriaCollection.push({
				criteria: and,
				op: Api.FilterOperator.And,
			});
		}

		if (or.length > 0) {
			criteriaCollection.push({
				criteria: or,
				op: Api.FilterOperator.Or,
			});
		}

		if (not.length > 0) {
			not.forEach(notTag => {
				criteriaCollection.push({
					op: Api.FilterOperator.Not,
					property: notTag.property,
					value: notTag.value,
				});
			});
		}

		return criteriaCollection;
	};

	private setFiltersWithCriteria = (criteria: Api.IContactFilterCriteria[]) => {
		const filters: AdvancedTagFilter[] = [];
		if (!!criteria && criteria.length > 0) {
			criteria.forEach(criterion => {
				if (!!criterion.criteria && criteria.length > 0) {
					criterion.criteria.forEach(y => {
						if (y.property === Api.ContactFilterCriteriaProperty.Tag && !!y.value) {
							const filter = new AdvancedTagFilter();
							filter.tag = y.value;
							filter.type = (criterion.op as Api.FilterOperator) || Api.FilterOperator.And;
							filters.push(filter);
						}
					});
				} else if (criterion.property === Api.ContactFilterCriteriaProperty.Tag && !!criterion.value) {
					const filter = new AdvancedTagFilter();
					filter.tag = criterion.value;
					filter.type = (criterion.op as Api.FilterOperator) || Api.FilterOperator.And;
					filters.push(filter);
				}
			});
		}
		this.mFilters = filters;
	};
}

export enum DefaultFabMenuItemActions {
	AddPhoneCall = 'AddPhoneCall',
	CreateActionItem = 'CreateActionItem',
	SendMessage = 'SendMessage',
	NewContact = 'NewContact',
}

/** Fab menu item */
export interface IFabMenuItem<T = any> {
	/** Visual element */
	icon: React.ReactNode;

	order?: number;

	/** Backing data for this menu item */
	representedObject?: T;

	/** Optional tooltip element to display onHover */
	tooltip?: React.ReactNode;
}

export interface IFabContext<T = any> {
	appearance?: {
		hidden?: boolean;
		styles?: StyleDeclarationValue[];
	};
	menuItems?: IFabMenuItem<T>[];
	onMenuItemSelected?(menuItem: IFabMenuItem<T>, e?: React.MouseEvent<HTMLElement>): void;
}

export enum AppAutoUpdaterEventName {
	AppAutoUpdaterWillRefresh = 'AppAutoUpdaterWillRefresh',
}

export interface IAppAutoUpdaterEvent<TTarget = any, TData = any> {
	preventDefault(): void;
	readonly data?: TData;
	readonly defaultPrevented: boolean;
	readonly name: AppAutoUpdaterEventName;
	readonly target?: TTarget;
}

export class AppAutoUpdaterEvent<TTarget = any, TData = any> implements IAppAutoUpdaterEvent<TTarget, TData> {
	protected mData: TData;
	protected mDefaultPrevented = false;
	protected mName: AppAutoUpdaterEventName;
	protected mOnDefaultPrevented: () => void;
	protected mTarget: TTarget;

	constructor(
		name: AppAutoUpdaterEventName,
		options?: {
			target?: TTarget;
			data?: TData;
			onDefaultPrevented?: () => void;
		}
	) {
		// @ts-ignore
		this.mData = options?.data;
		this.mName = name;
		// @ts-ignore
		this.mOnDefaultPrevented = options?.onDefaultPrevented;
		// @ts-ignore
		this.mTarget = options?.target;
	}

	public get name() {
		return this.mName;
	}

	public get target() {
		return this.mTarget;
	}

	public get data() {
		return this.mData;
	}

	public get defaultPrevented() {
		return this.mDefaultPrevented;
	}

	public preventDefault = () => {
		this.mDefaultPrevented = true;
		if (this.mOnDefaultPrevented) {
			this.mOnDefaultPrevented();
			// @ts-ignore
			this.mOnDefaultPrevented = null;
		}
	};
}

export interface IEditEmailTemplateContent {
	editorState: Api.IRichContentEditorState;
	subject?: string;
	template?: Api.ITemplate;
}

export interface IEditEmailTemplateComponent {
	getContent?(): IEditEmailTemplateContent;
	getIsSaving?(): boolean;
	hasUnsavedEdits?(): boolean;
}

export interface ICampaignApprovalCommand extends Api.IQueryStringCommand {
	id?: string;
}

export interface IAutomationInfo {
	automation?: Api.IAutomation;
	contact?: Partial<Api.IContact>;
	createAutomationFilterRequest?: Api.ICreateAutomationRequest;
}

export interface IManageAutomationModalResult {
	cancelled?: boolean;
}

export const DefaultSupportedIndustries = [
	Api.Industry.Insurance,
	Api.Industry.RealEstate,
	Api.Industry.Mortgage,
	Api.Industry.Financial,
	Api.Industry.Legal,
	Api.Industry.Accounting,
	Api.Industry.NonProfit,
	Api.Industry.HomeMaintenance,
	Api.Industry.Others,
];

export const AdminDefaultSupportedIndustries = [Api.Industry.Levitate, ...DefaultSupportedIndustries];

export interface IContactSelection {
	allowSenderSelection?: boolean;
	bulkFilterRequest?: Api.IEmailMessageContactsFilterRequest;
	bulkFilterRequestOptions?: {
		readOnlySearchCriteria?: boolean;
	};
	contacts?: Api.ContactViewModel[];
	contactsToOmit?: Api.ContactViewModel[];
}

export interface ICreateCampaignRequestBase<T = any> {
	context?: T;
	onDismiss?(): void;
	onFinish?(didSend: boolean): void;
	schedule?: Api.IScheduledSend;
	sendFromUser?: Partial<Api.IUser>;
	suggestedSchedule?: Api.ISuggestedSendSchedule;
}

export interface ICreateCampaignRequest<T = any> extends ICreateCampaignRequestBase<T> {
	tags?: string[];
	type: 'KeyDate' | 'Contacts' | 'Template' | 'ReachOutInfo';
}

export interface ICreateSocialMediaPostRequest<T = any> extends ICreateCampaignRequestBase<T> {
	impersonationContext?: Api.IImpersonationContext;
	targets?: Api.SocialMediaType[];
	type: 'Template' | 'TemplateId';
}

export interface IEditAutomationTemplateRequest<T = any> {
	automationTemplate: Api.AutomationTemplateViewModel;
	context?: T;
	onFinish?(): Promise<boolean>;
}

export interface IBulkEmailHistoricalReportRequest {
	viewModel: AccountOperationsViewModel | Api.CampaignViewModel;
	accountOperations: AccountOperationsViewModel;
}

export type TemplateOrTemplateFilter = Api.ITemplate | ((template: Api.ITemplate) => boolean);

export interface IKeyDateSelection {
	contactSelection?: IContactSelection;
	kind: Api.KeyDateKind;
	month?: number;
	template?: TemplateOrTemplateFilter;
}

export interface IRichContentDocumentEditorPlaceholder {
	additionalSymbols?: string[];
	className?: string;
	onClickCommand?: string;
	onCloseCommand?: string;
	symbol: string;
	text: string;
	value: string;
}

export interface IRichContentDocumentEditorImageOptions {
	base64EmbedOnInsert?: boolean;
	allowPasteImages?: boolean;
}

export interface ICampaignsCreatedNotificationInfo {
	accountId: string;
	campaigns: Api.ICampaign[];
}

export type RichContentSupportedTypes = 'ActionItem' | 'Note' | 'EmailNote';
export const AllRichContentSupportedTypes = ['ActionItem', 'Note', 'EmailNote'];

export interface INotesActionsCriteria {
	name: string;
	type: RichContentSupportedTypes | 'All';
}

export enum EmailMailerType {
	ClientEmailProvider = 'ClientEmailProvider',
	Levitate = 'Levitate',
}

export type NotesFilterOptionValue = 'byMe' | 'byAccount' | 'ownedBy';
export type NotesDateFilterOptionValue = 'all' | 'date';
export type NotesSortOptionValue =
	| 'all'
	| 'DueDate-asc'
	| 'DueDate-dsc'
	| 'CreationDate-asc'
	| 'CreationDate-dsc'
	| 'LastModifiedDate-asc'
	| 'LastModifiedDate-dsc';

export const disableFileAttachmentCommand = 'setFileAttachmentDisabled';
export const enableFileAttachmentCommand = 'setFileAttachmentEnabled';

export const levPlaceholderPopoverCommand = 'levPlaceholderPopoverCommand';
export const levPlaceholderPopoverCloseCommand = 'levPlaceholderPopoverCloseCommand';

export const levInsertGiphyCommand = 'levInsertGiphy';

export const ImpersonationContextKey = 'impersonationContext';

export interface IImpersonationContextComponentProps {
	impersonationContext?: ImpersonationContextViewModel;
}

export const AdminFullscreenModalName = 'adminFullscreenModal';

export enum SocialMediaPostStatusDetails {
	CancelledOrFailed,
	NotCancelledFailedDraftOrUnknown,
	NewOrDraft,
}

export const enum CardSize {
	Small = 'small',
	Medium = 'medium',
	Large = 'large',
}

export interface IContactsSelfImportEvent {
	searchResult?: Api.IContactsFilterRequest;
	preventDefault(): void;
}
export interface IContactsSelfImportRequest {
	openedFromPolicyBoard?: boolean;
	onFinish?(e: IContactsSelfImportEvent): void;
}

export interface IContactsSelfImportState {
	model: IContactsSelfImportRequest;
}
